import React from "react";
import { Form as FormikForm, Formik } from "formik";
import { useMutation } from "react-query";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import Input from "../component/Input";
import { toast } from "../component/toast";
import { adminChangePasswordValidationSchema } from "../utlis/validation";
import { Link, useNavigate } from "react-router-dom";
import { adminChangeOrgPassword } from "../handlers/adminHandler";
import AppButton from "../component/Button";

const AdminResetListingPassword = ({ organization_id }) => {
  console.log({ organization_id });

  const { mutate, isLoading } = useMutation(adminChangeOrgPassword, {
    onSuccess: async (res, variables) => {
      toast.success("Password changed successful");
      variables?.resetForm();
    },
    onError: (e) => {
      toast.error(e);
    },
  });

  const handleSubmit = (values, { resetForm }) => {
    mutate({
      organization_id: organization_id,
      password: values.new_password,
      resetForm,
    });
  };

  return (
    <div className="admin-listing-password">
      <div>
        <h1>Password Management</h1>
        <p>Please enter a new password</p>
      </div>
      <div className="admin-listing-password-form">
        <Formik
          initialValues={{
            new_password: "",
            new_password_confirmation: "",
          }}
          onSubmit={handleSubmit}
          validationSchema={adminChangePasswordValidationSchema}
        >
          {({ values, handleChange, handleSubmit, handleBlur }) => (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(e);
              }}
            >
              <Form.Group className="mb-3">
                <Input
                  type="password"
                  name="new_password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.new_password}
                  label="New Password"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Input
                  type="password"
                  name="new_password_confirmation"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.new_password_confirmation}
                  label="Confirm New Password"
                />
              </Form.Group>
              <div className="button-group mt-5">
                {/* <Button
                  className="btn btn-success login-transparent-btn"
                  type={"button"}
                >
                  Cancel
                </Button> */}
                <AppButton
                  className="login-btn"
                  type="submit"
                  loading={isLoading}
                >
                  Update Password
                </AppButton>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AdminResetListingPassword;
