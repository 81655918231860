import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import update from "../../img/update.svg";
import Table from "../../component/Table";
import dayjs from "dayjs";
import { Form as FormikForm, Formik } from "formik";
import Button from "../../component/Button";
import { useMutation, useQueryClient } from "react-query";
import {
  updateOrgStatus,
  bulkUpdateOrgStatus,
} from "../../handlers/adminHandler";
import { toast } from "../../component/toast";
import { allStates } from "../../utlis/helper";
import { Oval } from "react-loader-spinner";

const initial = {
  status: "",
  message: "",
  with_message: false,
};

function ListingAdmin({ data, isDataLoading, overviewData }) {
  const location = useLocation();
  useEffect(() => {
    const statusFromLocation = location.state
      ? location.state.statusFilter
      : "";
    setStatusFilter(statusFromLocation);
  }, [location.state]);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const closeRef = React.useRef();
  const closeBulkRef = React.useRef();
  const [selectedCSO, setSelectedCSO] = React.useState(null);
  const [selectedUpdatedable, setSelectedUpdatedable] = React.useState(true);
  const [locationFilter, setLocationFilter] = React.useState("");
  const [statusFilter, setStatusFilter] = React.useState("");
  const [thematicAreaFilter, setThematicAreaFilter] = React.useState("");
  const [bulkSelectedOrganizations, setBulkSelectedOrganizations] =
    React.useState([]);

  const [initialValues, setInitialValues] = React.useState(initial);
  const { mutate: updateOrgStatusMutation, isLoading: isLoading } = useMutation(
    updateOrgStatus,
    {
      onSuccess: async (res) => {
        toast.success("Listing updated successfully");
        closeRef.current.click();
        setSelectedCSO(null);
        setSelectedUpdatedable(true);
        await queryClient.invalidateQueries(["admin-all-csos"]);
      },
      onError: (e) => {
        toast.error(e);
      },
    }
  );

  const { mutate: bulkUpdateOrgStatusMutation, isLoading: isBulkLoading } =
    useMutation(bulkUpdateOrgStatus, {
      onSuccess: async (res) => {
        toast.success("Listings updated successfully");
        closeBulkRef.current.click();
        await queryClient.invalidateQueries(["admin-all-csos"]);
      },
      onError: (e) => {
        console.log({ e });
        toast.error(e);
      },
    });

  const filteredData = data
    ?.filter((item) => (statusFilter ? item.status === statusFilter : item))
    ?.filter((item) => (locationFilter ? item.state === locationFilter : item))
    ?.filter((item) =>
      thematicAreaFilter && item?.thematic_area
        ? item?.thematic_area.includes(thematicAreaFilter)
        : thematicAreaFilter && !item?.thematic_area
        ? undefined
        : item
    )
    ?.map((item) => {
      return {
        id: item.id,
        organization: item.organization,
        created_at: dayjs(item.created_at).format("DD/MM/YYYY"),
        email: item?.cso?.email,
        thematic_area: item.thematic_area,
        status: item.status,
        actions: "",
        bankdetail: item?.bankdetail,
        businessdoc: item?.businessdoc,
        ...item,
      };
    });

  const columns = [
    {
      name: "Registration Date",
      selector: "created_at",
      sortable: true,
      maxWidth: "160px",
      cell: (row) => <span>{dayjs(row.created_at).format("DD/MM/YYYY")}</span>,
    },
    {
      name: "Organization Name",
      selector: "organization",
      sortable: true,
      minWidth: "250px",
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      minWidth: "250px",
    },
    {
      name: "Thematic Area(s)",
      selector: "thematic_area",
      sortable: true,
      minWidth: "250px",
      cell: (row) => <span>{row.thematic_area?.join(", ")}</span>,
    },
    {
      name: "Listing Status",
      selector: "status",
      sortable: true,
      maxWidth: "150px",
      cell: (row) => (
        <div style={{ minHeight: 23 }}>
          {row.status === "rejected" ? (
            <span className="reject">
              <i className="bi bi-circle-fill me-2"></i>Rejected
            </span>
          ) : row.status === "approved" ? (
            <span className="approved">
              <i className="bi bi-circle-fill me-2"></i>Approved
            </span>
          ) : row.status === "awaiting" ? (
            <span className="pending">
              <i className="bi bi-circle-fill me-2"></i>Awaiting
            </span>
          ) : (
            <span className="pending">
              <i className="bi bi-circle-fill me-2"></i>Pending
            </span>
          )}
        </div>
      ),
    },
    {
      name: "Actions",
      selector: "actions",
      maxWidth: "100px",
      cellExport: () => "",
      cell: (row) => (
        <div className="dropdown table-dropdown">
          <i
            className="bi bi-three-dots-vertical actions"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          ></i>
          <ul className="dropdown-menu listing-drop">
            <li>
              <Link
                className="dropdown-item listing-item"
                to={`/admin/view-listing/${row.id}`}
              >
                <i className="bi bi-eye me-2"></i> View Listing Details
              </Link>
            </li>
            <li>
              <div
                className="dropdown-item listing-item"
                data-bs-toggle="modal"
                data-bs-target="#updatelistingdetails"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setSelectedCSO(row);
                  row.status === "pending"
                    ? setSelectedUpdatedable(true) // check this later.
                    : setSelectedUpdatedable(true);
                }}
              >
                <i className="bi bi-arrow-repeat me-2"></i> Update Listing
                Status
              </div>
            </li>
          </ul>
        </div>
      ),
    },
  ];

  const handleClearFilter = () => {
    setLocationFilter("");
    setStatusFilter("");
    setThematicAreaFilter("");
  };

  const handleSubmit = (values) => {
    if (selectedUpdatedable) {
      updateOrgStatusMutation({ id: selectedCSO?.id, data: values });
    } else {
      toast.info("CSO hasn't completed their profile");
    }
  };
  const handleBulkSubmit = (values) => {
    const organization_ids = bulkSelectedOrganizations
      ?.filter((item) => item.status === "awaiting")
      ?.map((item) => item.id);

    if (organization_ids?.length > 0) {
      bulkUpdateOrgStatusMutation({
        ...values,
        organization_id: organization_ids,
      });
    } else {
      toast.info("CSOs hasn't completed their profile");
    }
  };

  return (
    <section>
      <div className="content overview">
        <div className="row">
          <div className="col-md-12 mb-2">
            <h2>Listings</h2>
          </div>
        </div>
        <div className="p-4 group-cards gray mt-4">
          <form className="row">
            <div className="col-md-3">
              <div className="mb-3">
                <label className="form-label">State</label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={locationFilter}
                  onChange={(e) => setLocationFilter(e.target.value)}
                >
                  <option value="">All</option>
                  {allStates.map((item, idx) => (
                    <option key={idx} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label className="form-label">Listings Status</label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={statusFilter}
                  onChange={(e) => setStatusFilter(e.target.value)}
                >
                  <option value="">All</option>
                  <option value="pending">Pending</option>
                  <option value="awaiting">Awaiting</option>
                  <option value="rejected">Rejected</option>
                  <option value="approved">Approved</option>
                </select>
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label className="form-label">Thematic Area</label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={thematicAreaFilter}
                  onChange={(e) => setThematicAreaFilter(e.target.value)}
                >
                  <option value="">All</option>
                  <option value="Education">Education</option>
                  <option value="Disability">Disability</option>
                  <option value="Poverty">Poverty</option>
                  <option value="Health">Health</option>
                  <option value="Economic/Community Development">
                    Economic/Community Development
                  </option>
                  <option value="Democracy and Good Governance">
                    Democracy and Good Governance
                  </option>
                  <option value="Orphanages and Shelters">
                    Orphanages and Shelters
                  </option>
                  <option value="Civil Rights and Social Action">
                    Civil Rights and Social Action
                  </option>
                  <option value="Peace and Conflict Resolution">
                    Peace and Conflict Resolution
                  </option>
                  <option value="Corporative and Microfinance">
                    Corporative and Microfinance
                  </option>
                  <option value="Crime and Law Related">
                    Crime and Law Related
                  </option>
                  <option value="Youth and Youth Development">
                    Youth and Youth Development
                  </option>
                  <option value="Information Communication and Technology">
                    Information Communication and Technology
                  </option>
                  <option value="Trade">Trade</option>
                  <option value="Migration">Migration</option>
                  <option value="Gender and Social Inclusion">
                    Gender and Social Inclusion
                  </option>
                  <option value="Philanthropy">Philanthropy</option>
                  <option value="Research and Development">
                    Research and Development
                  </option>
                  <option value="Environment">Environment</option>
                  <option value="Water, Sanitation and Hygiene.">
                    Water, Sanitation and Hygiene.
                  </option>
                  <option value="Religion Related ">Religion Related</option>
                </select>
              </div>
            </div>
            <div className="col-md-3 text-end align-content-center">
              <button
                type="button"
                className="btn btn-primary bg-white text-muted btn-claer px-3"
                onClick={handleClearFilter}
              >
                Clear all
              </button>
            </div>
          </form>
        </div>
        <div className="row mt-5">
          <div className="col mb-3">
            <div className="bg-listed">
              <h6>Total No of Registered Orgs</h6>
              <h5>{overviewData?.organizations || "-"}</h5>
            </div>
          </div>
          <div className="col mb-3">
            <a
              href="#!"
              onClick={() => setStatusFilter("approved")}
              style={{ textDecoration: "none" }}
            >
              <div className="bg-listed" style={{ backgroundColor: "#FCF9EE" }}>
                <h6>Total No of Approved Orgs</h6>
                <h5>{overviewData?.approved_organizations || "-"}</h5>
              </div>
            </a>
          </div>
          <div className="col mb-3">
            <a
              href="#!"
              onClick={() => setStatusFilter("pending")}
              style={{ textDecoration: "none" }}
            >
              <div className="bg-listed" style={{ background: "#EEFCF2" }}>
                <h6>Total No of Pending Orgs</h6>
                <h5>{overviewData?.pending_organizations || "-"}</h5>
              </div>
            </a>
          </div>
          <div className="col mb-3">
            <a
              href="#!"
              onClick={() => setStatusFilter("rejected")}
              style={{ textDecoration: "none" }}
            >
              <div className="bg-listed" style={{ background: "#F6F7FE" }}>
                <h6>Total No of Rejected Orgs</h6>
                <h5>{overviewData?.rejected_organizations || "-"}</h5>
              </div>
            </a>
          </div>
          <div className="col mb-3">
            <a
              href="#!"
              onClick={() => setStatusFilter("awaiting")}
              style={{ textDecoration: "none" }}
            >
              <div className="bg-listed" style={{ background: "#FCEEEE" }}>
                <h6>Total No of Awaiting Orgs</h6>
                <h5>{overviewData?.awaiting_organizations || "-"}</h5>
              </div>
            </a>
          </div>
        </div>
        <div className="bg-white group-cards mt-4">
          {isDataLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 200,
              }}
            >
              <Oval wrapperClass="ml-2" height={30} width={30} />
            </div>
          ) : (
            <div style={{ paddingTop: "30px" }}>
              <Table
                data={filteredData || []}
                columns={columns}
                isAdmin={true}
                listingStatusUpdate={
                  <a
                    className="dropdown-item table-nav-link"
                    data-bs-toggle="modal"
                    data-bs-target="#bulkUpdateListingDetails"
                    style={{ cursor: "pointer" }}
                  >
                    <i className="bi bi-arrow-repeat me-2"></i> Update Listing
                    Status
                  </a>
                }
                setBulkSelectedOrganizations={setBulkSelectedOrganizations}
              />
            </div>
          )}
        </div>
      </div>
      {/* Update Modal */}
      <div
        className="modal fade"
        id="updatelistingdetails"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content px-3">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeRef}
                onClick={() => {
                  setSelectedCSO(null);
                  setSelectedUpdatedable(true);
                }}
              ></button>
            </div>
            {selectedCSO && (
              <div className="modal-body">
                <div className="row listing-modal">
                  <div className="col">
                    <img
                      src={update}
                      alt="update"
                      className="img-fluid mb-3"
                      style={{ marginTop: "-30px" }}
                    />
                    <h4>Update {selectedCSO?.organization}</h4>
                    <p>Update organization listing status</p>
                    <Formik
                      initialValues={initialValues}
                      onSubmit={(values) => handleSubmit(values)}
                      // enableReinitialize={true}
                    >
                      {({
                        values,
                        handleChange,
                        handleSubmit,
                        handleBlur,
                        setFieldValue,
                        errors,
                      }) => (
                        <FormikForm className="row" onSubmit={handleSubmit}>
                          <div className="mb-3">
                            <label className="form-label">Listing Status</label>
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              name="status"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.status}
                              required={true}
                            >
                              <option value="">Select Status</option>
                              <option value="awaiting">Awaiting</option>
                              <option value="pending">Pending</option>
                              <option value="rejected">Rejected</option>
                              <option value="approved">Approved</option>
                              <option value="deactivated">Deactivate</option>
                            </select>
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Send a Message</label>
                            <textarea
                              className="form-control"
                              placeholder="Enter a message"
                              rows="7"
                              name="message"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.message}
                              required={false}
                            ></textarea>
                          </div>
                          <div className="mb-3">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={values.with_message}
                                name="with_message"
                                onChange={(e) =>
                                  setFieldValue(
                                    "with_message",
                                    e.target.checked
                                  )
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckDefault"
                              >
                                Send notification email
                              </label>
                            </div>
                          </div>
                          <div className="d-flex flex-row">
                            <Button
                              type={"button"}
                              className="btn btn-success bg-white w-100 login-btn mt-3 me-3"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={() => {
                                setSelectedCSO(null);
                                setSelectedUpdatedable(true);
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              type={"submit"}
                              className="btn btn-success w-100 login-btn mt-3"
                              loading={isLoading}
                            >
                              Confirm
                            </Button>
                          </div>
                        </FormikForm>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Bulk Update Modal */}
      <div
        className="modal fade"
        id="bulkUpdateListingDetails"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="bulkUpdateListingDetails"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content px-3">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeBulkRef}
              ></button>
            </div>
            {bulkSelectedOrganizations?.length > 0 && (
              <div className="modal-body">
                <div className="row listing-modal">
                  <div className="col">
                    <img
                      src={update}
                      alt="update"
                      className="img-fluid mb-3"
                      style={{ marginTop: "-30px" }}
                    />
                    <h4>Update {selectedCSO?.organization}</h4>
                    <p>Update organization listing status</p>
                    <Formik
                      initialValues={initialValues}
                      onSubmit={(values) => handleBulkSubmit(values)}
                      // enableReinitialize={true}
                    >
                      {({
                        values,
                        handleChange,
                        handleSubmit,
                        handleBlur,
                        setFieldValue,
                        errors,
                      }) => (
                        <FormikForm className="row" onSubmit={handleSubmit}>
                          <div className="mb-3">
                            <label className="form-label">Listing Status</label>
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              name="status"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.status}
                              required={true}
                            >
                              <option value="">Select Status</option>
                              <option value="pending">Pending</option>
                              <option value="rejected">Rejected</option>
                              <option value="approved">Approved</option>
                              <option value="deactivated">Deactivate</option>
                            </select>
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Send a Message</label>
                            <textarea
                              className="form-control"
                              placeholder="Enter a message"
                              rows="7"
                              name="message"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.message}
                              required={false}
                            ></textarea>
                          </div>
                          <div className="mb-3">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={values.with_message}
                                name="with_message"
                                onChange={(e) =>
                                  setFieldValue(
                                    "with_message",
                                    e.target.checked
                                  )
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckDefault"
                              >
                                Send notification email
                              </label>
                            </div>
                          </div>
                          <div className="d-flex flex-row">
                            <Button
                              type={"button"}
                              className="btn btn-success bg-white w-100 login-btn mt-3 me-3"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            >
                              Cancel
                            </Button>
                            <Button
                              type={"submit"}
                              className="btn btn-success w-100 login-btn mt-3"
                              loading={isBulkLoading}
                            >
                              Confirm
                            </Button>
                          </div>
                        </FormikForm>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default ListingAdmin;
